
































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  StudentController,
  CourseController,
  CCACurriculumController,
  SsRoomAttendanceController,
} from '@/services/request.service'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import { toPage, getSchoolInfo } from '@/utils/utils'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    TableTotal,
    FlexTooltip,
    filterGroup,
  },
})
export default class ClassViewer extends Vue {
  // @Prop()private readonly viewerType:any
  private dropDownList: Array<any> = []
  private filter: any = {
    id: undefined,
  }
  private pagination: any = createPagination({})
  private exportLoading: boolean = false
  private data: Array<any> = []
  private isTeach: boolean = false
  private selectedRowKeys: Array<any> = []
  private loading: boolean = false
  private relationName: any = ''
  private get locale(): string {
    return this.$store.state.locale
  }
  private get dataEmptyFlag(): boolean {
    return !this.data.length
  }
  private get sendAuth(): boolean {
    let operationAuths = this.$store.state.operationAuths
    if (operationAuths.includes('2013')) {
      return true
    } else {
      return false
    }
  }
  private get homeworkAuth(): boolean {
    let operationAuths = this.$store.state.operationAuths
    const hasAuth = operationAuths.includes('2061') && this.viewerType !== 'CCAClass'
    if (hasAuth && (this.viewerType === 'subjectClass' || this.viewerType === 'CCAClass')) {
      return true
    } else {
      return false
    }
  }
  private get diaryAuth(): boolean {
    let operationAuths = this.$store.state.operationAuths
    if (operationAuths.includes('2011')) {
      return true
    } else {
      return false
    }
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }
  private get isChildren(): boolean {
    return this.$route.name !== 'studentList'
  }
  private get viewerType(): any {
    return this.$route.params.studentListType
  }
  private get relationId(): any {
    return this.$route.params.id
  }
  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 15,
      },
      {
        dataIndex: 'gender',
        title: this.$t('myClass.student.gender'),
        width: 15,
      },
      {
        dataIndex: 'sectionName',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'houseGroupName',
        title: this.$t('common.advisory'),
        width: 20,
      },
      {
        dataIndex: 'studentEmail',
        title: this.$t('myClass.student.studentEmail'),
        width: 20,
      },
      {
        dataIndex: 'parentsEmail',
        title: this.$t('myClass.student.parentEmail'),
        width: 40,
      },
    ]
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('myClass.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: this.viewerType === 'subjectClass' ? 'code' : 'studentNum',
        width: 150 * this.ratio,
        title: this.$t('myClass.studentId'),
      },
      {
        dataIndex: 'gender',
        width: 100 * this.ratio,
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'gender' },
      },
      {
        dataIndex: this.viewerType === 'subjectClass' ? 'grade' : 'sectionName',
        width: 100 * this.ratio,
        title: this.$t('myClass.section'),
      },
      {
        dataIndex: 'className',
        width: 150 * this.ratio,
        title: this.$t('common.homeroom'),
      },
      {
        key: 'houseGroupName',
        title: this.$t('common.advisory'),
        width: 250 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'advisory' },
      },
      {
        dataIndex: 'studentEmail',
        width: 200 * this.ratio,
        title: this.$t('myClass.student.studentEmail'),
      },
      {
        dataIndex: 'parentEmail',
        width: 200 * this.ratio,
        title: this.$t('myClass.student.parentEmail'),
      },
    ]
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private publishHomework(): void {
    this.$router.push({
      name: 'assignmentEditor',
      params: { assignType: this.viewerType },
      query: { type: 'add', defaultClassId: this.relationId },
    })
  }

  private created() {
    this.relationName = this.$route.query.name
    this.getData()
  }

  private addDiary(): void {
    // if (!this.selectedRowKeys.length) {
    //   this.$message.error(this.$tc('exam.batchSelectError'))
    //   return
    // }
    this.$store.commit('setDiaryStudents', this.selectedRowKeys)
    this.$router.push({ name: 'diaryEditor', query: { type: 'add' } }).catch(err => {})
  }

  private dealDrowDownList(data): Array<any> {
    return data.map(item => {
      return {
        ...item,
        subOptions: item.subOptions.map(it => {
          return {
            key: it.key,
            value: it.value,
            enValue: it.enValue,
            isTeach: it.isTeach,
          }
        }),
      }
    })
  }

  private getData(
    page = { current: 1, pageSize: this.pagination.defaultPageSize },
    isPage = false
  ): void {
    if (!isPage) {
      this.getStudentIdList()
    }
    this.data = []
    this.loading = true
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    this.pagination.current = page.current
    if (this.viewerType === 'homeroom') {
      ;(condition as any).classId = this.relationId
      StudentController.getList(condition)
        .then(this.dealDataRes)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else if (this.viewerType === 'subjectClass') {
      CourseController.getCourseStudents(this.relationId, condition.pageCurrent, condition.pageSize)
        .then(this.dealDataRes)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else if (this.viewerType === 'advisory') {
      ;(condition as any).houseGroupId = this.relationId
      StudentController.getList(condition)
        .then(this.dealDataRes)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else if (this.viewerType === 'CCAClass') {
      ;(condition as any).courseId = this.relationId
      CCACurriculumController.students(condition)
        .then(this.dealDataRes)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    } else if (this.viewerType === 'dormitory' || this.viewerType === 'studyRoom') {
      if (this.viewerType === 'dormitory') {
        ;(condition as any).floorId = this.relationId
      } else {
        ;(condition as any).selfStudyRoomId = this.relationId
      }
      StudentController.getList(condition)
        .then(this.dealDataRes)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private dealDataRes(res): void {
    if (this.viewerType === 'subjectClass') {
      this.data = res.data.items.map(element => {
        this.$set(element, 'studentName', element.name)
        return element
      })
    } else {
      this.data = res.data.items.map(element => {
        this.$set(
          element,
          'studentName',
          (element.enName + ' ' + element.lastName + ' ' + element.name).trim()
        )
        return element
      })
    }
    this.pagination.total = res.data.totalItem
    toPage(this.pagination, this.data, page => {
      this.pagination.current = page
      this.refresh()
    })
  }

  private async exportStudents(): Promise<void> {
    this.exportLoading = true
    const typeCode = {
      homeroom: 1,
      subjectClass: 2,
      advisory: 3,
      CCAClass: 4,
      dormitory: 5,
      studyRoom: 6,
    }
    StudentController.getExportList(typeCode[this.viewerType], this.relationId)
      .then(async res => {
        const students = res.data.students.map(student => ({
          ...student,
          gender: student.gender ? this.$t(`common.gender.${student.gender}`) : '',
          parentsEmail: student.parentsEmail ? student.parentsEmail.join('; ') : '',
        }))
        const teacherName = res.data.teachers.join('、')
        const file = exportXlsx(this.excelColumns, students)
        const ws = file.getWorksheet(1)

        let className = this.relationName
        const title = `${this.$t(`common.${this.viewerType}`)}: ${className} ${teacherName}`

        ws.insertRow(1, [title])
        ws.mergeCells(1, 1, 1, 11)
        ws.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })

        await writeFile(file, 'Student Infomation.xlsx')
        this.exportLoading = false
      })
      .catch(err => console.log(err))
      .finally(() => (this.exportLoading = false))
  }

  private copyStudentEmail(): void {
    const studentList: any = []
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
    } else {
      this.data.forEach(item => {
        if (this.selectedRowKeys.includes(item.studentId)) {
          studentList.push(item.studentEmail)
        }
      })
      this.copyList(studentList)
    }
  }

  // 复制邮箱
  private copyParentEmail(): void {
    const parentList: any = []
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
    } else {
      this.data.forEach(item => {
        if (this.selectedRowKeys.includes(item.studentId)) {
          parentList.push(item.parentEmail)
        }
      })
      this.copyList(parentList)
    }
  }

  private copyList(list): void {
    const text = list.join(';')
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination, true)
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  private viewDetail(studentId): void {
    this.$router.push({
      name: 'classStudentDetail',
      params: { studentId },
      query: { name: this.relationName },
    })
  }

  private sendMessage(): void {
    if (this.selectedRowKeys.length) {
      this.$store.commit('setMessageStudents', this.selectedRowKeys)
      this.$router.push({ name: 'sendMessage' })
    } else {
      this.$message.error(this.$tc('common.batchSelectError'))
    }
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private clearSelection(): void {
    this.selectedRowKeys = []
  }

  private onCourseChange(params, selectOptions): void {
    if (selectOptions[1].isTeach) {
      this.isTeach = selectOptions[1] ? false : selectOptions[1].isTeach
    }
    this.$nextTick(() => {
      this.getData()
    })
  }

  private onCCAClassChange(params, option): void {
    if (this.viewerType === 'CCAClass') {
      this.isTeach = this.dropDownList.filter(item => item.key === params)[0].extraValue
    }
    this.getData()
  }

  private getStudentIdList(): void {
    const typeCode = {
      homeroom: 1,
      subjectClass: 2,
      advisory: 3,
      CCAClass: 4,
      dormitory: 5,
      studyRoom: 6,
    }
    StudentController.getStudentIds(typeCode[this.viewerType], this.relationId).then(res => {
      const result = res.data.map(item => {
        return {
          key: item,
          value: true,
        }
      })
      this.$store.commit('setAllStudentIds', result)
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (to.name === 'studentList' && !to.query.name) {
      this.$router.replace({ query: { name: from.query.name } })
    }
  }
}
