










































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import {
  CourseController,
  DropDownController,
  StudentController,
  NoticeController,
} from '@/services/request.service'
import moment from 'moment'
import Attendance from '../Attendance/Attendance.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
  },
})
export default class MyClass extends Vue {
  // 公告列表数据
  private data: any = {}
  // 加载
  private loading: boolean = false

  private get locale(): string {
    return this.$store.state.locale
  }

  private moment = moment

  // 获取数据
  private getData(): void {
    this.loading = false
    this.data = []
    let condition = {
      pageCurrent: 1,
      pageSize: 4,

      clearTags: true,
    }
    this.loading = true
    NoticeController.getNoticePersonal(condition)
      .then(res => {
        this.data = res.data.items
        if (this.data) {
          this.data.forEach(item => {
            if (item.noticeTags.length) {
              item.noticeTags.forEach(tag => {
                const rgb: any = []
                const temp = tag.extraValue.split('#')[1]
                for (let i = 0; i < 3; i++) {
                  rgb.push(parseInt(temp.slice(i * 2, i * 2 + 2), 16))
                }
                tag.borderColor = 'rgba(' + rgb.join(',') + ',1)'
                tag.backgroundColor = 'rgba(' + rgb.join(',') + ',0.25)'
              })
            }
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private viewDetail(id): void {
    NoticeController.setRead(id)
    this.$router.push({ name: 'noticeViewHome', query: { id: id } })
  }

  private activeKey1: any = [1, 2]
  private activeKey2: any = [1, 2]
  private customStyle: any = ''
  private teacherLoading: any = false
  private dateLoading: any = false
  private filters: any = {
    date: moment(),
    teacherId: undefined,
  }
  private aData: any = []
  private tData: any = []
  private teachers: any = []
  private keyCode: any = {
    houseList: 'advisory',
    classList: 'homeroom',
    floorList: 'dormitory',
    selfStudyRoomList: 'studyRoom',
    courseList: 'subjectClass',
    ccaList: 'CCAClass',
  }
  private Vue = Vue
  private get entries(): any {
    return [
      {
        id: 1,
        key: 'attendance',
      },
      {
        id: 2,
        key: 'teaching',
      },
    ].filter(item => this.userAuths.includes(item.key))
  }
  private get menuAuth(): any {
    return this.$store.state.userAuths || {}
  }

  private get isChildren(): boolean {
    return this.$route.name !== 'home'
  }

  private get userAuths(): any {
    return this.$store.state.routeAuths
  }

  private allMatch(regExpArr, title): boolean {
    let result = true
    regExpArr.forEach(text => {
      if (!text.test(title)) {
        result = false
      }
    })
    return result
  }

  private filterOption(input, option): any {
    console.log(input, option)
    const regExpArr = input
      .split(' ')
      .map(item => new RegExp(`(.*)(${item.split('').join(')(.*)(')})(.*)`, 'i'))
    return this.allMatch(regExpArr, option.componentOptions.children[0].text)
  }

  private getColumns(type): any {
    return [
      {
        dataIndex: 'type',
        title: this.$t(`common.type`),
        scopedSlots: { customRender: 'type' },
        width: 150,
        // customRender: (value, row, index) => {
        //   return {
        //     children: this.$t(`common.${value}`),
        //     attrs: {
        //       rowSpan: row.rowSpan,
        //     },
        //   };
        // },
      },
      {
        dataIndex: 'name',
        ellipsis: true,
        title: this.$t('common.class'),
        scopedSlots: { customRender: 'name' },
      },
      type !== 'all' && {
        key: 'classPeriodName',
        title: this.$t('attendance.period'),
        scopedSlots: { customRender: 'period' },
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.student'),
        scopedSlots: { customRender: 'studentNum' },
      },
      {
        key: 'quickEntry',
        title: this.$t('common.quickEntry'),
        // width: 400,
        scopedSlots: { customRender: 'quickEntry' },
      },
    ].filter(item => item)
  }

  private toDetailPage(record, type) {
    this.$router.push({
      name: 'studentList',
      params: { id: record.id, studentListType: type },
      query: { name: record.name },
    })
  }

  private getAllData(): void {
    this.getDataByTeacher()
    this.getDataByDate()
  }

  private getDataByTeacher(): void {
    this.teacherLoading = true
    this.aData = []
    // 全部
    StudentController.getClassAndHouse(this.filters.teacherId)
      .then(res => {
        let data = [] as any
        Object.keys(res.data).forEach(key => {
          data = data.concat(
            res.data[key].map(item => ({
              ...item,
              type: this.keyCode[key],
            }))
          )
        })
        const existTypes = [] as any
        this.aData = data.map((item, index) => {
          let flag = false
          if (!existTypes.includes(item.type)) {
            existTypes.push(item.type)
            flag = true
          }
          return {
            ...item,
            rowSpan: flag ? data.filter(it => it.type === item.type).length : 0,
          }
        })
        // couresFlag=false的删除
        this.aData = this.aData.filter(item => {
          return item.couresFlag == undefined || item.couresFlag == true
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.teacherLoading = false))
  }

  private getDataByDate(): void {
    this.dateLoading = true
    this.tData = []
    // 今日
    CourseController.getCourseAndCcaList(this.filters.teacherId)
      .then(res => {
        let data: any = []
        Object.keys(res.data).forEach(key => {
          data = data.concat(
            res.data[key].map(item => ({
              ...item,
              type: this.keyCode[key],
            }))
          )
        })
        let existTypes: any = []
        this.tData = data.map((item, index) => {
          let flag = false
          if (!existTypes.includes(item.type)) {
            existTypes.push(item.type)
            flag = true
          }
          return {
            ...item,
            rowSpan: flag ? data.filter(it => it.type === item.type).length : 0,
          }
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.dateLoading = false))
  }

  private toRoutePage(key, record, type) {
    switch (key) {
      case 'attendance':
        if (type === 'homeroom') {
          this.$router.push({ name: 'kindergartenAtten', query: { homeroomId: record.id } })
        } else if (type === 'advisory') {
          this.$router.push({
            name: 'kindergartenAtten',
            query: { houseGroupId: record.id },
          })
        } else if (type === 'studyRoom') {
          this.$router.push({ name: 'studyRoomAtten', query: { id: record.id } })
        } else if (type === 'dormitory') {
          this.$router.push({ name: 'dormitoryAtten', query: { id: record.id } })
        } else {
          this.$router.push({
            name: 'attendanceViewer',
            params: { attendanceType: type },
            query: {
              id: record.id,
              periodId: record.classPeriodId,
              date: this.filters.date.valueOf(),
            },
          })
        }
        break
      case 'teaching':
        this.$router.push({ name: key, query: { id: record.id, type: record.type } })
        break
    }
  }

  private created(): void {
    this.getDropDownInfo()
    this.getData()
  }

  private getDropDownInfo(): void {
    DropDownController.authTeachersForNyClass()
      .then(res => {
        this.teachers = res.data
        if (this.teachers.length) {
          this.filters.teacherId = this.teachers[0].teacherId
          this.getDataByTeacher()
          this.getDataByDate()
        }
      })
      .catch(err => console.log(err))
  }

  private showEntry(record, id, all): any {
    switch (record.type) {
      case 'homeroom':
        return Number(record.attendanceType) === 57 && this.menuAuth.classAtten && id === 1
      case 'subjectClass':
        return id === 1
          ? !all
            ? this.menuAuth.courseTeacher || this.menuAuth.courseManager
            : false
          : true
      case 'studyRoom':
        return this.menuAuth.selfStudyRoom && id === 1
      case 'advisory':
        return (this.menuAuth.houseGroupTeacher || this.menuAuth.houseTeacher) && id === 1
      case 'dormitory':
        return this.menuAuth.dormitory && id === 1
      case 'CCAClass':
        return id === 3
          ? record.courseFlag
          : id === 1
          ? !all
            ? this.menuAuth.ccaCourseTeacher
            : false
          : true
      default:
        return false
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(): void {
    this.getData()
  }
}
