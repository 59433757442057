






























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { NoticeController } from '@/services/request.service'
import moment from 'moment'

@Component
export default class AnnouncementDetail extends Vue {
  // 公告id
  private noticeId = Number(this.$route.query.id)
  // 公告详情数据
  private data: any = {}
  // 加载
  private loading: boolean = false

  private moment = moment

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.getNoticeDetail()
  }

  private getNoticeDetail(): void {
    this.loading = true
    NoticeController.getNotice(this.noticeId)
      // NoticeController.getNotice(5)
      .then(res => {
        this.data = res.data
        this.data.noticeTags.forEach(item => {
          const rgb: any = []
          const temp = item.extraValue.split('#')[1]
          for (let i = 0; i < 3; i++) {
            rgb.push(parseInt(temp.slice(i * 2, i * 2 + 2), 16))
          }
          item.borderColor = 'rgba(' + rgb.join(',') + ',1)'
          item.backgroundColor = 'rgba(' + rgb.join(',') + ',0.25)'
        })
        // console.log(this.data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.loading = false
      })
  }
}
